
<div class="search-frame">
  <Selector class="v-selector" @selectElement="updateLocation" :init_selected="search.location" :init_elements="locations"  :placeholder="'Нас. пункт'"/>
  <Selector class="v-selector" @selectElement="updateCategory" :init_selected="search.category" :init_elements="categories" :placeholder="'Категория'"/>
  <Selector class="v-selector" v-if="showRooms" @selectElement="updateRooms" :init_selected="search.rooms" :init_elements="flat_rooms" :placeholder="'Комнат'"/>
  <Selector class="v-selector" v-if="search.category === 'houses'" @selectElement="updateHouseType" :init_selected="search.house_type" :init_elements="house_types" :placeholder="'Тип'"/>
  <Selector class="v-selector" v-if="search.category === 'lots'" @selectElement="updateLotType" :init_selected="search.lot_type" :init_elements="lot_types" :placeholder="'Назначение'"/>
  <Selector class="v-selector" v-if="search.category === 'commercial'" @selectElement="updateCommercialType" :init_selected="search.commercial_type" :init_elements="commercial_types" :placeholder="'Тип'"/>
  <RangeSelector class="v-range-selector" key="area_select" v-if="showArea" :strategy="'area'" :init_from="search.s_from.toString()"  :init_to="search.s_to.toString()" @selectRange="updateArea"/>
  <RangeSelector class="v-range-selector" key="lot_area_select" v-if="showLotArea" :strategy="'lot_area'" :init_from="search.lot_from.toString()" :init_to="search.lot_to.toString()" @selectRange="updateLotArea"/>
  <RangeSelector class="v-range-selector price-selector" key="price_select" :strategy="'price'" :init_from="search.price_from.toString()"  :init_to="search.price_to.toString()" @selectRange="updatePrice"/>

  <div class="search-btn-wrapper"><span class="srch" @click="goSearch">Найти</span></div>
</div>
