function initCommunicator() {
    const comm = document.querySelector('#communicator')
    const head = comm.querySelector('.c-head')
    const body = comm.querySelector('.c-body')
    const phone_input = comm.querySelector('.c-phone input')

    if (comm.classList.contains('mobile')) {
        const opener = document.querySelector('.c-mobile-opener')
        const closer = document.querySelector('.mobile-close')
        opener.addEventListener('click', function() {
            comm.classList.remove('hidden')
        })
        closer.addEventListener('click', function() {
            comm.classList.add('hidden')
        })
        opener.classList.remove('hidden')
    } else {
        head.addEventListener('click', function () {
            body.classList.toggle('hidden')
            if (!body.classList.contains('hidden')) {
                phone_input.focus()
            }
        })
        comm.classList.remove('hidden')
    }
    phone_input.addEventListener('input', handlePhoneInput, false)

}

function handlePhoneInput (e) {
    e.target.value = phoneMask(e.target.value)
}

function phoneMask(phone) {
    return phone.replace(/\D/g, '')
        .replace(/^(\d)/, '($1')
        .replace(/^(\(\d{3})(\d)/, '$1) $2')
        .replace(/(\d{3})(\d{1,4})/, '$1-$2')
        .replace(/(-\d{5})\d+?$/, '$1');
}


function run() {
    document.addEventListener('DOMContentLoaded', () => {
        if (document.querySelector('#communicator') !== null ) {
            initCommunicator()
        }
    })
}

export default {run}