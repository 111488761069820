
  <div class="selector-wrapper" v-on-clickaway="clickAway">
    <div class="selector" :class="active ? 'active' : ''" @click="toggleActive">
      <span>{{selected.length > 0 ? selected : placeholder}}</span>
<!--      <span></span>-->
    </div>

    <div class="menu" v-if="active">
      <div class="menu-element" v-for="element in elements" @click="selectElement(element)" :key="element.slug">
        {{element.title}}
      </div>
    </div>

  </div>
