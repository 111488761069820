
<div class="selector-wrapper" v-on-clickaway="clickAway">
  <div class="selector" :class="active ? 'active' : ''" @click="toggleActive">
    <span>{{caption}}</span>
    <span></span>
  </div>

  <div class="menu" v-if="active">
    <div class="input-wrapper">
      <input v-model="frrom" type="text" placeholder="от" class="range-input" :class="`${strategy}-input`" v-currency="v_options" v-on:input="updateParent">

    </div>
    <div class="input-wrapper">
      <input v-model="to" type="text" placeholder="до" class="range-input" :class="`${strategy}-input`" v-currency="v_options" v-on:input="updateParent">
    </div>
  </div>

</div>
