
  <div class="offer-contact">
    <div v-if="sending">
      <p>Отправка сообщения ...</p>
    </div>
    <div v-else>
      <div v-if="done">
        <p style="color:green;">Сообщение отправлено</p>
      </div>
      <div v-else>
        <input type="text" placeholder="Имя" v-model="name"/>
<!--        <input type="text" placeholder="Телефон" v-model="phone" />-->
        <the-mask :mask="'(###) ###-##-##'" placeholder="Телефон" v-model="phone" />
<!--        <input type="email" placeholder="Email" v-model="pochta"/>-->
        <textarea placeholder="Сообщение" rows="5" v-model="comment"></textarea>
        <div class="text-center">
          <span class="btn" @click="sendMessage">Отправить</span>
        </div>
      </div>

    </div>


  </div>
