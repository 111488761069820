function rebuildFooter() {
    let container = document.querySelector('.container');
    let contacts = document.querySelector('footer .contacts');
    let menu = document.querySelector('footer .menu');
    let margin = window.getComputedStyle(container).getPropertyValue("margin-left");
    contacts.style.paddingLeft = margin;
    menu.style.paddingRight = margin;
}

function run() {
    const returnedFunction = debounce(function() {
        rebuildFooter();
    }, 300);

    window.addEventListener('resize', returnedFunction);

    document.addEventListener('DOMContentLoaded', () => {
        if (document.querySelector('#mobile-mark') === null ) {
            rebuildFooter()
        }
    })
}

export default {run}