<template>
  <div class="offer-contact">
    <div v-if="sending">
      <p>Отправка сообщения ...</p>
    </div>
    <div v-else>
      <div v-if="done">
        <p style="color:green;">Сообщение отправлено</p>
      </div>
      <div v-else>
        <input type="text" placeholder="Имя" v-model="name"/>
<!--        <input type="text" placeholder="Телефон" v-model="phone" />-->
        <the-mask :mask="'(###) ###-##-##'" placeholder="Телефон" v-model="phone" />
<!--        <input type="email" placeholder="Email" v-model="pochta"/>-->
        <textarea placeholder="Сообщение" rows="5" v-model="comment"></textarea>
        <div class="text-center">
          <span class="btn" @click="sendMessage">Отправить</span>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
  export default {
    name: 'OfferContact',
    props: ['offer'],
    data: function() {
      return {
        name:'',
        phone:'',
        pochta:'',
        comment:'',
        sending: false,
        done: false
      }
    },
    methods: {
      sendMessage() {
        const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        const headers = {'X-CSRF-Token': csrf,'X-Requested-With' : 'XMLHttpRequest','Content-Type': 'application/json'};
        const data = {
          name: this.name,
          phone: this.phone,
          pochta: this.poschta,
          comment: this.comment,
          offer: this.offer
        }
        const _this = this
        this.sending = true
        fetch('/msg',{
          method:'POST',
          headers: headers,
          body:JSON.stringify(data)
        })
            .then((response) => {
              return response.json();
            })
            .then((data) => {
              _this.sending = false
              _this.done = true
            });
      }
    }
  }
</script>

<style lang="scss" scoped>
  input[type='text'], input[type='email'], textarea {
    width: 100%;
    margin-bottom: 20px;
    background-color: #F5F5F5;
    //border-color: #F5F5F5;
    border: 1px solid #F5F5F5;
    -webkit-appearance: none;
    -moz-appearance: none;
    resize: none;
    padding: 11px 8px;
  }

  .btn {
    display: flex;
    height: 50px;
    //padding: 0 20px;
    background-color: #1D72B3;
    color: white;
    width: 120px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
  }

</style>