<template>
  <div class="selector-wrapper" v-on-clickaway="clickAway">
    <div class="selector" :class="active ? 'active' : ''" @click="toggleActive">
      <span>{{caption}}</span>
      <span></span>
    </div>

    <div class="menu" v-if="active">
      <div class="input-wrapper">
        <input v-model="frrom" type="text" placeholder="от" class="range-input" :class="`${strategy}-input`" v-currency="v_options" v-on:input="updateParent">

      </div>
      <div class="input-wrapper">
        <input v-model="to" type="text" placeholder="до" class="range-input" :class="`${strategy}-input`" v-currency="v_options" v-on:input="updateParent">
      </div>
    </div>

  </div>
</template>

<script>
  import {mixin as clickaway} from "vue-clickaway";
  import { parse } from "vue-currency-input";

  export default {
    name: 'RangeSelector',
    props: ['init_from','init_to','strategy'],
    mixins: [ clickaway ],
    data: function () {
      return {
        frrom: '',
        to: '',
        active: false,
        v_options: {
          currency:null,
          locale:'sv-SE',
          precision: 0,
          allowNegative: false,
          distractionFree: false
        }
      }
    },
    mounted() {
      this.frrom = this.init_from;
      this.to = this.init_to;

    },
    methods: {
      updateParent() {
        let f = '';
        let t = '';
        if (this.from_digit !== null) {
          f = this.from_digit
        }
        if (this.to_digit !== null) {
          t = this.to_digit
        }

        this.$emit('selectRange',{from:f,to:t});
      },
      clickAway() {
        this.active = false;
      },
      toggleActive() {
        this.active = !this.active
      }
    },
    computed: {
      suffix() {
        if (this.strategy === 'price') {
          return '₽'
        } else if (this.strategy === 'area') {
          return 'м²'
        } else if (this.strategy === 'lot_area') {
          return 'сот.'
        }
      },
      from_digit() {
        return parse(this.frrom, this.v_options);
      },
      to_digit() {
        return parse(this.to, this.v_options);
      },
      caption() {
        let t = ''
        let f = ''

        if (this.from_digit > 999999) {
          f = `${Number((this.from_digit/1000000).toFixed(1))} млн.`
        } else {
          if (this.frrom !== null) {
            f = this.frrom.replace(` ${this.suffix}`,'')
          }
        }

        if (this.to_digit > 999999) {
          t = `${Number((this.to_digit/1000000).toFixed(1))} млн.`
        } else {
          if (this.to !== null) {
            t = this.to.replace(` ${this.suffix}`,'')
          }
        }

        if (this.from_digit > 0 && this.to_digit > 0) {
          return `${f} - ${t} ${this.suffix}`
        } else if (this.from_digit > 0) {
          return `от ${f} ${this.suffix}`
        } else if (this.to_digit > 0) {
          return `до ${t} ${this.suffix}`
        } else {
          if (this.strategy === 'price') {
            return 'Стоимость'
          } else if (this.strategy === 'area') {
            return 'Площадь'
          } else if (this.strategy === 'lot_area'){
            return 'Площадь (сот.)'
          }
        }
      }
    },
    watch :{
      init_from : function(i_from) {
        this.frrom = i_from;
      },
      init_to : function(i_to) {
        this.to = i_to;
      },
      strategy: function (st) {
        this.strategy = st;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .menu {
    padding: 30px;
    display: flex;
    justify-content: space-between;

    .input-wrapper {
      box-sizing: border-box;
      outline: none;
      box-shadow: none;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #d3d4d4;
      border-radius: 5px;
      vertical-align: middle;
      background-color: #fff;
      transition: border-color .2s;
      transition-property: border-color;
      transition-duration: 0.2s;
      transition-timing-function: ease;
      transition-delay: 0s;
      &:first-child {
        margin-right: 15px;
      }
    }


    input {
      font-family: SeroPro,sans-serif;
      font-style: normal;
      letter-spacing: normal;
      font-stretch: normal;
      text-decoration: none;
      text-transform: none;
      text-indent: 0;
      text-shadow: none;
      width: 100%;
      height: calc(100% - 2px);
      margin: 1px 0;
      padding: 11px 12px;
      border: 0;
      border-radius: 5px;
      font-size: 16px;
      outline: none;
      text-overflow: ellipsis;
      background-color: initial;
      box-shadow: none;
      box-sizing: border-box;
    }

    .price-input {
      width: 130px;
    }
  }

  .mobile-search {
    .menu {
      padding: 10px;
      flex-direction: column;
      justify-content: flex-start;
      min-width: auto!important;
      width:180px;
      .input-wrapper {
        margin: 0;
        display: block;
        &:first-child {
          margin-bottom: 10px;
        }
      }
      .price-input {
        width: 100%;
      }
    }
  }
</style>