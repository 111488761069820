import Rails from '@rails/ujs';
Rails.start();
import footer from '../custom/footer'
import communicator from '../custom/communicator'
footer.run()
communicator.run()

import Vue from 'vue';
import Search from '../components/search/search.vue';
import OfferContact from '../components/offer_contact/offer_contact.vue';
// import Calculator from '../components/mortgage/mortgage.vue';
import Splide from '@splidejs/splide';


import VueCurrencyInput from "vue-currency-input";
import VueTheMask from 'vue-the-mask'

Vue.use(VueCurrencyInput);
Vue.use(VueTheMask);

document.addEventListener('DOMContentLoaded', () => {
    let search = document.querySelector('#vue-search')
    if (search !== null) {
        new Vue({
            render: h => h(Search,{
                props:{}
            }),
        }).$mount(search)
    }

    let offer_contact = document.querySelector('#vue-offer-contact')
    if (offer_contact !== null) {
        new Vue({
            render: h => h(OfferContact,{
                props:{
                    offer:offer_contact.dataset.offer
                }
            }),
        }).$mount(offer_contact)
    }

    let calculator = document.querySelector('#v-m-calc')
    if (calculator) {
        new Vue({
            render: h => h(Calculator,{
                props:{
                    init_price: calculator.dataset.price
                }
            }),
        }).$mount(calculator)
    }

    if (document.getElementById('map') !== null) {
        ymaps.ready(function(){
            let data = JSON.parse(document.getElementById('map').dataset.coords);

            let zoom = 15;
            let long = data.lon === null ? 37.323087 : data.lon;
            let lat = data.lat === null ? 44.889844 : data.lat;
            let mapInitOptions = {
                center: [lat, long],
                zoom: zoom,
                controls: ['zoomControl','typeSelector','rulerControl']
            };

            let YaMap = new ymaps.Map('map', mapInitOptions);
            let placemarkStyle = {
                preset: 'twirl#houseIcon',
                draggable: false,
                iconColor: '#1D72B3'
            };

            let YaPlacemark = new ymaps.Placemark([lat, long], {balloonContent: ''}, placemarkStyle);
            YaMap.geoObjects.add(YaPlacemark);
        });
    }


    if (document.querySelector('.splide')) {
        new Splide( '.splide',{
            perPage: 1,
            perMove: 1,
            autoHeight: true,
            pagination: false,
            // direction: 'ttb',
            heightRatio: 1,
            gap: 5
        } ).mount();
    }


})

document.addEventListener('click', function(event) {
    let target = event.target;
    if (target.classList.contains('photo-counter')) {
        document.getElementById('offer-big-image').click();
    }

    if (target.classList.contains('slide-image')) {
        lightGallery( document.createElement('div'),{
                dynamic: true,
                dynamicEl: window.gon.photos,
                index: target.dataset.index,
                thumbnail:true
            }
        );
    }

    if (target.classList.contains('burger')) {
        document.getElementById("mobile-sidebar").style.width = "250px";
    }

    if (target.classList.contains('side-closer')) {
        document.getElementById("mobile-sidebar").style.width = "0";
    }
})


