<template>
  <div class="selector-wrapper" v-on-clickaway="clickAway">
    <div class="selector" :class="active ? 'active' : ''" @click="toggleActive">
      <span>{{selected.length > 0 ? selected : placeholder}}</span>
<!--      <span></span>-->
    </div>

    <div class="menu" v-if="active">
      <div class="menu-element" v-for="element in elements" @click="selectElement(element)" :key="element.slug">
        {{element.title}}
      </div>
    </div>

  </div>
</template>

<script>
import {mixin as clickaway} from "vue-clickaway";

export default {
  name: 'Selector',
  props: ['init_selected','init_elements','placeholder'],
  mixins: [ clickaway ],
  data: function () {
    return {
      elements: [],
      active: false,
      selected: ''
    }
  },
  mounted() {
    this.elements = this.init_elements;
    if (this.init_selected.length > 0) {
      const el = this.elements.find(e => e.slug === this.init_selected);
      this.selected = el.title;
    }

  },
  methods: {
    selectElement(el) {
      this.$emit('selectElement',el)
      this.active = false
      this.selected = el.title
    },
    clickAway() {
      this.active = false
    },
    toggleActive() {
      this.active = !this.active
    }
  },
  computed: {
    current_element() {
      return this.elements.find(e => e.slug === this.selected);
    }
  },
  watch :{
    init_elements: function(els) {
      this.elements = els;
    },
    init_selected : function(sel) {
      if (sel.length > 0) {
        const el = this.elements.find(e => e.slug === sel);
        this.selected = el.title;
      } else {
        this.selected = '';
      }
    }
  }
}
</script>

<style lang="scss" scoped>


</style>